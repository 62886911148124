import tw, { css, styled } from 'twin.macro'

import { Link } from 'gatsby'
import { ChevronLeft, ChevronRight, PlayCircle } from 'react-feather'
import { animated } from 'react-spring'
import { SliderMarker } from '@reach/slider'
import MineralSwimLogo from '../../components/svg/mineralSwim'
import EcoClear from '../../components/svg/ecoClear'
import Maytronics from '../../components/svg/maytronics'
import { Plus } from 'react-feather'
import { CustomCheckboxContainer } from '@reach/checkbox'

export const HeroSection = styled.section`
	height: 850px;
	min-height: 850px;
	${tw`w-full bg-cover bg-no-repeat bg-center`}
	background-color: #c5cbc0;
	background-image: url(${({ sources }) => sources[0].base64});

	@media (min-width: 768px) and (max-width: 1023px) {
		height: 600px;
		min-height: 600px;
	}
	@media (min-width: 525px) and (max-width: 767px) {
		height: 500px;
		min-height: 500px;
	}

	@media (max-width: 524px) {
		height: 400px;
		min-height: 400px;
	}
`
export const HeroItemsContainer = styled.div`
${tw`flex flex-col w-full h-full pt-4 px-2 md:px-0 md:pt-12 items-center`}
	${tw`bg-cover bg-no-repeat bg-center`}
	min-height: 850px;
	@media (min-width: 1350px) {
		${tw`bg-top`}
		background-image: url(${({ sources }) => sources[0].srcWebp});
		background-image: url(${({ sources }) => sources[0].src});
	}
	@media (min-width: 1024px) and (max-width: 1349px) {
		background-image: url(${({ sources }) => sources[1].srcWebp});
		background-image: url(${({ sources }) => sources[1].src});
	}
	@media (min-width: 768px) and (max-width: 1023px) {
		background-image: url(${({ sources }) => sources[2].srcWebp});
		background-image: url(${({ sources }) => sources[2].src});
		height: 600px;
		min-height: 600px;
	}
	@media (min-width: 525px) and (max-width: 767px) {
		background-image: url(${({ sources }) => sources[3].srcWebp});
		background-image: url(${({ sources }) => sources[3].src});
		height: 500px;
		min-height: 500px;
	}
	@media (max-width: 524px) {
		background-image: url(${({ sources }) => sources[4].srcWebp});
		background-image: url(${({ sources }) => sources[4].src});
		height: 400px;
		min-height: 400px;
	}
`
export const HeroButtons = styled.div`
	@media (min-width: 525px) {
		${tw`flex-row`}
	}
	${tw`flex flex-col sm:flex-row mt-2 mt-4 lg:mt-8`}
`
export const HeroSeeAllLink = styled(Link)`
	@media (min-width: 525px) {
		${tw`mb-0 mr-2`}
	}
	${tw`border bg-yellow-500 border-yellow-500 hover:border-yellow-400 hover:bg-yellow-400 rounded-lg mb-2 mr-0 sm:mb-0 sm:mr-2`}
`
export const HeroSeeAll = tw.button`font-sans text-dolphin-gray uppercase text-xs md:text-sm lg:text-base py-2 md:py-3 lg:py-4 px-8 leading-none w-full `
export const HeroBookLink = styled(Link)`
	@media (min-width: 525px) {
		${tw`ml-0`}
	}
	${tw`border-2 border-dolphin-gray bg-white hover:bg-dolphin-gray rounded-lg md:ml-2`}
`
export const HeroBook = tw.button`font-sans text-dolphin-gray hover:text-white leading-none text-xs md:text-sm lg:text-base uppercase py-2 md:py-3 lg:py-4 px-8 `
export const Bold = tw.b`font-bold`
export const Heading1 = styled.h1`
	@media (min-width: 525px) {
		${tw`text-3xl my-6`}
	}
	${tw`font-display text-greyish-blue font-bold text-2xl sm:text-3xl md:text-4xl lg:text-5xl xl:text-5xl text-center my-2 md:w-9/12 xl:w-8/12 xxl:w-7/12`}
`
export const BannerText = styled.p`
	@media (min-width: 525px) {
		${tw`block`}
	}
	${tw`font-display text-greyish-blue font-bold hidden text-lg md:text-2xl xl:text-3xl mb-6`}
`

export const OzoneLogo = styled.img`
	@media (min-width: 525px) {
		${tw`w-64`}
	}
	${tw`w-56 md:w-5/12 my-2 mt-0 mb-4`}
`

export const FaqSection = tw.section`w-full bg-gray-300 py-16`
export const FaqContainer = tw.div`w-11/12 lg:w-9/12 m-auto items-center justify-center flex flex-col relative`
export const FaqTitle = tw.p`text-2xl md:text-4xl xl:text-5xl font-display px-4 md:mx-0 font-bold text-greyish-blue text-center leading-none`
export const AnimatedFaqTitle = animated(FaqTitle)

export const FaqPrev = styled(ChevronLeft)`
	${tw`cursor-pointer absolute`}
	top: 0;
	left: -5%;
`
export const FaqNext = styled(ChevronRight)`
	${tw`cursor-pointer absolute`}
	top: 0;
	right: -5%;
`
export const LearnMore = styled(Link)`
	${tw`border-2 border-mineral-teal rounded-lg px-8 py-2 md:px-10 md:py-3 font-display mt-16 hover:bg-mineral-teal hover:text-white text-base md:text-lg`}
`
export const Label = styled.label`
	${tw`flex items-center uppercase tracking-wide text-xs font-normal text-gray-500 w-full md:w-7/12 lg:w-8/12 mx-auto mb-1 md:mb-0`}
`

export const FilterBannerSection = styled.section`
	height: 700px;
	min-height: 700px;
	${tw`w-full bg-cover bg-no-repeat bg-center`}
	background-color: #c5cbc0;
	background-image: url(${({ sources }) => sources[0].base64});

	@media (min-width: 768px) and (max-width: 1023px) {
		height: 600px;
		min-height: 600px;
	}
	@media (min-width: 325px) and (max-width: 767px) {
		height: 500px;
		min-height: 500px;
	}
`
export const FilterBannerContainer = styled.div`
	@media (min-width: 525px) {
		${tw`pl-8`}
	}
	${tw`flex flex-col w-full h-full pl-4 xl:pl-24 items-center justify-center`}
	${tw`bg-cover bg-no-repeat`}
	min-height: 700px;
	@media (min-width: 325px) and (max-width: 524px) {
		height: 500px;
		min-height: 500px;
		background-image: url(${({ sources }) => sources[6].srcWebp});
		background-image: url(${({ sources }) => sources[6].src});
		background-position: 30% 100%;
	}
	@media (min-width: 525px) {
		height: 500px;
		min-height: 500px;
		background-image: url(${({ sources }) => sources[5].srcWebp});
		background-image: url(${({ sources }) => sources[5].src});
	}
	@media (min-width: 768px) {
		height: 600px;
		min-height: 600px;
		background-image: url(${({ sources }) => sources[4].srcWebp});
		background-image: url(${({ sources }) => sources[4].src});
	}
	@media (min-width: 1024px) {
		background-position: right;
		height: 700px;
		min-height: 700px;
		background-image: url(${({ sources }) => sources[3].srcWebp});
		background-image: url(${({ sources }) => sources[3].src});
	}
	@media (min-width: 1280px) {
		background-image: url(${({ sources }) => sources[2].srcWebp});
		background-image: url(${({ sources }) => sources[2].src});
	}
	@media (min-width: 1400px) {
		background-image: url(${({ sources }) => sources[1].srcWebp});
		background-image: url(${({ sources }) => sources[1].src});
	}
	@media (min-width: 1700px) {
		background-image: url(${({ sources }) => sources[0].srcWebp});
		background-image: url(${({ sources }) => sources[0].src});
	}
`

export const FilterSection = styled.div`
	${tw`w-11/12 xl:w-10/12 xxl:w-9/12 pb-0 pt-10 md:py-10 mx-auto -mt-20 bg-white px-2 mb-16`}
	box-shadow: 2px 15px 30px 5px rgba(0,0,0,0.35);
`
export const FilterContainer = tw.div`lg:w-11/12 m-auto`
export const FilterHeading = styled.p`
	@media (min-width: 525px) {
		${tw`flex-row ml-6`}
	}
	${tw`flex flex-col text-base text-xl lg:text-4xl xl:text-5xl xxxl:text-6xl font-display mb-8 items-baseline ml-3 items-center`}
`
export const OzoneImg = styled.img`
	@media (min-width: 525px) {
		${tw`mx-2 w-36 mb-0 my-0`}
	}
	${tw`w-32 md:w-56 xl:w-64 mb-2 -mt-3 mt-2 mb-4`}
`
export const FiltersContainer = tw.div`flex flex-col w-11/12 md:w-4/12 mx-auto md:mx-6 mb-8 md:mb-0 justify-center`
export const FilterTtl = tw.label`mb-6 uppercase text-xs font-display font-bold`
export const Form = styled.form`
	${tw`w-full flex flex-col md:flex-row`}
`
export const PostCodeContainer = tw.div`flex flex-row relative`
export const PostCode = styled.input`
		${tw`flex appearance-none w-full py-2 pl-4 pr-10`}
		${tw`text-gray-500 leading-tight`}
		${tw`bg-white`}
		${tw`focus:shadow-outline focus:outline-none`}
		${tw`border-2 border-black rounded`}
		&::placeholder {
			${tw`text-gray-400`}
		}
		&[readonly] {
			${tw`bg-gray-400 border-gray-400 rounded`}
		}
		&:focus {
		box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.25);
		}

		&::-webkit-search-cancel-button,
		::-webkit-search-results-decoration,
		::-webkit-search-cancel-button,
		::-webkit-search-decoration {
				-webkit-appearance:none;
		}
`
export const SearchBtn = tw.button`absolute right-0 top-0 bottom-0 px-2 bg-yellow-400 border-2 border-black border-l-0 rounded`

export const StyledSliderMarker = styled(SliderMarker)`
	${tw`rounded-full w-3 h-3 border-2 border-white bg-gray-400`}
	&[data-reach-slider-marker-highlight] {
		${tw`bg-black`}
	}
`
export const FilterBannerText = styled.h2`
	@media (min-width: 525px) {
		${tw`text-4xl w-6/12`}
	}
	${tw`font-display text-3xl md:text-5xl lg:text-6xl text-white font-bold w-8/12 mr-auto`}
`
export const FilterBannerP = styled.p`
	@media (min-width: 525px) {
		${tw`text-3xl w-6/12`}
	}
	${tw`font-display text-2xl md:text-4xl lg:text-5xl text-white w-8/12 mr-auto mt-6`}
`
export const FilterProductSection = tw.section`w-full bg-gray-200 pb-8`

export const ProductsHolder = tw.div`flex flex-col md:px-4 w-full xl:w-4/5 xl:w-11/12 xxxl:w-10/12 m-auto items-center justify-center`
export const ProductsContainer = tw.div`w-11/12 md:w-full m-auto flex flex-col md:flex-row flex-wrap md:px-2`
export const OtherProductsContainer = tw.div`w-full m-auto flex flex-col md:flex-row flex-wrap`
export const ProductSpace = tw.div`w-full px-2 mb-4 mx-auto`
export const OtherProductSpace = styled.div`
	${({ wid }) => (wid ? tw`w-11/12 md:w-6/12` : tw`w-11/12 md:w-4/12`)};
	${tw`px-4 py-2 mb-4 mx-auto md:mx-0`}
	&:nth-of-type(3n + 1) {
		${tw`pl-2 lg:pl-4 pr-2`}
	}
	&:nth-of-type(3n + 2) {
		${tw`px-2`}
	}
	&:nth-of-type(3n + 3) {
		${tw`pr-2 lg:pr-4 pl-2`}
	}
`
export const ProductItem = styled.div`
	box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
		0 10px 10px -5px rgba(0, 0, 0, 0.04);
	${tw`flex flex-col w-full h-full`}
`
export const ProductTitle = tw.h3`text-lg md:text-xl lg:text-2xl xl:text-3xl italic font-display font-bold text-blue-700 bg-white text-center p-4 mb-auto xl:mb-3`
export const ProductDetails = tw.div`flex flex-col bg-white h-full mt-3 xl:mt-0`
export const MainProductDetails = styled(ProductDetails)`
	${tw`md:flex-row`}
`
export const ProductImgContainer = styled.div`
	max-height: 400px;
	${tw`flex items-center justify-center h-screen my-16 md:w-6/12`}
	&:hover {
		img {
			opacity: 0.875;
		}
	}
	img {
		${tw`h-full`}
		@media (min-width: 325px) {
			max-height: 200px;
		}

		@media (min-width: 525px) {
			max-height: 300px;
		}
		@media (min-width: 768px) {
			max-height: 220px;
			height: 90%;
		}
		@media (min-width: 1024px) {
			max-height: 300px;
		}
		@media (min-width: 1280px) {
			max-height: 350px;
		}
		@media (min-width: 1400px) {
			max-height: 400px;
		}
	}
	@media (min-width: 325px) {
		max-height: 200px;
	}

	@media (min-width: 525px) {
		max-height: 300px;
	}
	@media (min-width: 768px) {
		max-height: 220px;
	}
	@media (min-width: 1024px) {
		max-height: 300px;
	}
	@media (min-width: 1280px) {
		max-height: 350px;
	}
	@media (min-width: 1400px) {
		max-height: 400px;
	}
`
export const ProductImg = styled.picture`
	${tw`cursor-pointer`}
`
export const ProductSeries = styled.p`
	${tw`text-3xl text-center font-display mb-4`}
	span {
		${tw`font-bold`}
	}
`
export const OtherProductSeries = styled(ProductSeries)`
	${tw`md:text-xl lg:text-2xl px-2 mt-2`}
`
export const ProductName = tw.p`font-display text-center text-blue-300 text-2xl font-bold mb-4`
export const ProductDescription = tw.p`font-display text-center text-gray-500 px-10 leading-normal mb-8`
export const OtherProductDescription = styled(ProductDescription)`
	${tw`md:px-3 lg:px-10`}
`
export const ProductButtons = tw.div`flex flex-col md:flex-row justify-center items-center w-10/12 md:w-11/12 xl:w-9/12 xxl:w-8/12 mx-auto mb-8 md:mb-0`
export const OtherProductButtons = styled(ProductButtons)`
	${tw`xl:w-11/12 xxl:w-8/12 mt-auto mb-8 md:mb-8`}
`
export const Button = styled.button`
	${tw`font-display w-full md:w-6/12 uppercase border-2 border-yellow-600 hover:border-yellow-500 bg-yellow-600 hover:bg-yellow-500 text-white py-2 px-6 rounded cursor-pointer md:mr-2 mb-2 md:mb-0`}
	&[disabled] {
		${tw`cursor-not-allowed`}
	}
`
export const OtherButton = styled(Button)`
	${tw`md:text-xs px-2 xl:text-base md:mr-1`}
`
export const ItemDetails = styled.div`
	${tw`w-full md:w-6/12 lg:w-8/12 xl:w-6/12 m-auto md:ml-2`}
`
export const OtherItemDetails = styled(ItemDetails)`
	${tw`w-full md:w-6/12 md:ml-1`}
`
export const Continue = tw.button`w-full border-2 border-sky hover:border-sky bg-transparent hover:bg-sky hover:text-white text-sky py-2 px-6 rounded uppercase font-display`
export const OtherContinue = styled(Continue)`
	${tw`md:text-xs xl:text-base`}
`

export const AddOnsSection = styled.section`
	${tw`w-full py-8`}
	background-color: #c0e0e7;
`
export const AddOnsContainer = tw.div`w-full`
export const AddOnsSlider = styled.div`
	${({ arrLen }) =>
		arrLen === 2
			? tw`w-10/12 xl:w-7/12 xxxl:w-6/12`
			: arrLen === 1
			? tw`w-10/12 md:w-8/12 lg:w-7/12 xl:w-5/12 xxl:w-4/12`
			: tw`w-10/12 xxl:w-9/12`}
	${tw`mx-auto relative`}
`
export const AddOnSliderInner = tw.div`overflow-hidden`
export const AddOnsSliderContainer = tw.div`flex flex-row`
export const AddOnsSpace = styled.div`
	${({ arrLen }) =>
		arrLen === 2
			? tw`w-full md:w-6/12 xl:w-6/12`
			: arrLen === 1
			? tw`w-full`
			: tw`md:w-6/12 xl:w-4/12`}
	${tw`px-2 flex-none`}
`
export const ItemContainer = tw.div`p-3 h-full`
export const AddOnItemDetails = styled.div`
	${tw`flex flex-col bg-white text-center h-full relative overflow-hidden`}

	&:hover {
		.overlay {
			${tw`opacity-0`}
			z-index: -1;
		}
	}
`
export const SliderTitle = styled.h3`
	${tw`mx-2 font-display text-center mb-8 text-blue-700 font-semibold text-base md:text-xl relative`}

	span {
		@media (min-width: 525px) {
			${tw`px-4 py-0 py-2`}
		}
		${tw`px-0 relative`}
		z-index: 1;
		background-color: #c0e0e7;
	}
	:after {
		content: '';
		@media (min-width: 525px) {
			${tw`block`}
		}
		${tw`absolute border-t border-blue-800 left-0 right-0 hidden`}
		top: 50%;
		z-index: 0;
	}
`
export const ItemOverlay = styled.div`
	${tw`absolute top-0 right-0 left-0 bottom-0 md:flex md:flex-col items-center pt-10 pb-16 px-4 hidden`}
	transition: 0.3s ease-in-out;
	background: rgba(88, 128, 148, 0.9);
`
export const OverLaySvg = styled(Plus)`
	${tw`mt-12 mb-8`}
`
export const OverLayMineralSwim = styled(MineralSwimLogo)`
	${tw`w-64`}
`
export const EcoClearLogo = styled(EcoClear)`
	${tw`w-64`}
`
export const MaytronicsLogo = styled(Maytronics)`
	${tw`w-64`}
`
export const OverlayTitle = styled.p`
	${tw`text-4xl lg:text-5xl text-white font-semibold uppercase font-display tracking-wider my-auto`}

	span {
		${tw`text-6xl lg:text-7xl`}
	}
`
export const ItemImageContainer = styled.div`
	max-height: 350px;
	${tw`flex items-center justify-center pt-12 px-4 h-screen cursor-pointer`}

	> img {
		${tw`h-full`}
	}

	> .addons > img {
		max-height: 350px;
	}
`
export const NullText = tw.p`font-display text-white text-center uppercase text-xl`
export const ItemTitle = tw.p`font-display font-bold text-dolphin-gray text-2xl uppercase my-8 text-center`
export const ItemDetail = tw.p`font-thin text-gray-600 font-display text-lg`
export const ItemDetailsContainer = tw.div`flex flex-col p-4 items-center mb-4`
export const ButtonsContainer = tw.div`flex flex-col items-center mt-auto mb-4 w-10/12 md:w-full mx-auto`
export const AddToCart = styled.button`
	${tw`font-display w-full md:w-7/12 uppercase border border-yellow-600 hover:border-yellow-500 bg-yellow-600 hover:bg-yellow-500 text-white py-2 md:py-3 px-6 rounded cursor-pointer mb-2`}
	&[disabled] {
		${tw`cursor-not-allowed`}
	}
`
export const Details = styled(Link)`
	${tw`font-display w-full md:w-7/12 uppercase text-center border border-blue-700 bg-white hover:bg-blue-700 text-blue-700 hover:text-white py-2 md:py-3 px-6 rounded cursor-pointer`}
`

export const Previous = styled.button`
	${tw`absolute`}
	left: -6%;
	top: 50%;
	svg {
		${tw`w-8/12 md:w-auto mx-auto`}
	}
	@media (max-width: 1365px) {
		left: -7%;
	}
	@media (max-width: 1023px) {
		left: -9%;
	}

	@media (max-width: 767px) {
		left: -12%;
	}

	@media (max-width: 524px) {
		left: -15%;
	}

	&:focus:focus {
		${tw`outline-none shadow-none`}
	}
`
export const Next = styled.button`
	${tw`absolute`}
	right: -6%;
	top: 50%;
	svg {
		${tw`w-8/12 md:w-auto mx-auto`}
	}
	@media (max-width: 1365px) {
		right: -7%;
	}
	@media (max-width: 1023px) {
		right: -9%;
	}

	@media (max-width: 767px) {
		right: -12%;
	}

	@media (max-width: 524px) {
		right: -15%;
	}

	&:focus:focus {
		${tw`outline-none shadow-none`}
	}
`

export const PurificationSection = tw.section`w-full py-20`
export const PurificationContainer = tw.div`w-11/12 xl:w-9/12 xxl:w-7/12 m-auto flex flex-col items-center`
export const Hook = tw.h3`text-greyish-blue font-display text-3xl font-bold mb-8 text-center`
export const Paragraph = tw.p`text-mineral-teal text-center mb-6 font-display text-lg`
export const StepsContainer = styled.div`
	@media (min-width: 525px) {
		${tw`flex-row mt-16`}
	}
	${tw`flex flex-col w-full mt-4 mb-6`}
`
export const StepsImageHolder = styled.div`
	@media (min-width: 525px) {
		${tw`w-5/12`}
	}
	${tw`w-10/12 mx-auto`}

	max-height: 350px;
`
export const StepsImage = styled.img`
	${tw`h-full m-auto`}
`
export const StepsList = styled.ul`
	@media (min-width: 525px) {
		${tw`w-7/12`}
	}
	${tw`w-full flex flex-col justify-center md:pl-8 lg:pl-0`}
`
export const StepsItem = styled.li`
	${tw`font-display my-5 pl-24 relative`}

	&:nth-of-type(2) {
		&:after {
			${tw`absolute border-b-2 border-gray-400`}
			content: '';
			width: 40px;
			left: 0;
			bottom: -25%;
			transform: rotate(90deg);
		}
	}

	&:first-of-type {
		&:after {
			${tw`absolute border-b-2 border-gray-400`}
			content: '';
			width: 40px;
			left: 0;
			bottom: -25%;
			transform: rotate(90deg);
		}
	}
	&:before {
		${tw`absolute`}
		content: '';
		left: 0;
		width: 40px;
		height: 40px;
		border-radius: 9999px;
		background-color: ${({ bgc }) => bgc};
	}
`
export const StepsItemTtl = tw.p`text-2xl font-bold uppercase mb-2`
export const StepsDesc = tw.p``

export const ExploreNow = styled(Link)`
	${tw`border bg-yellow-500 border-yellow-500 hover:border-yellow-400 hover:bg-yellow-400 rounded-lg mb-2 mr-0 md:mb-0 md:mr-2 px-10 py-4 uppercase mt-8 w-full md:w-auto text-center`}
`

export const BenefitsSection = styled.section`
	${tw`w-full bg-cover mb-4 md:mb-0 bg-no-repeat bg-center`}
	background-color: #c0e0e7;
`
export const BenefitsContainer = styled.div`
	${tw`flex flex-col w-full h-full pt-4 px-2 md:px-0 md:py-12`}
`
export const List = styled.ul`
	${tw`w-full lg:w-11/12 md:w-10/12 lg:w-11/12 xxl:w-9/12 flex flex-col md:flex-row flex-nowrap md:flex-wrap`}
	margin: 0 auto;
`
export const ListItem = styled.li`
	${tw`relative flex flex-row mb-4 md:my-8 leading-snug md:w-6/12 lg:w-4/12 px-6`}

	> svg {
		${tw`w-1/12`}
		flex: none;
	}

	> p {
		${tw`w-11/12 text-greyish-blue text-base md:text-lg font-display`}
		flex: none;
	}
`

export const BannerFormSection = tw.section`w-full`
export const BannerFormContainer = tw.div`flex flex-col md:flex-row`

export const LeftContent = styled.div`
	${tw`w-full md:w-6/12 flex flex-col bg-cover bg-no-repeat px-10 lg:px-20 py-8 lg:py-14 bg-center`}
	@media (min-width: 768px) {
		height: 700px;
		min-height: 700px;
	}
	@media (min-width: 1280px) {
		height: 750px;
		min-height: 750px;
	}
	@media (min-width: 1400px) {
		height: 800px;
		min-height: 800px;
	}
	background-image: url(${({ sources }) => sources.fluid.base64});
	background-image: url(${({ sources }) => sources.fluid.srcWebp});
	background-image: url(${({ sources }) => sources.fluid.src});
`
export const RightContent = styled.div`
	@media (min-width: 768px) {
		height: 700px;
		min-height: 700px;
	}
	@media (min-width: 1280px) {
		height: 750px;
		min-height: 750px;
	}
	@media (min-width: 1400px) {
		height: 800px;
		min-height: 800px;
	}
	${tw`w-full md:w-6/12 flex flex-col px-10 lg:px-20 py-8 lg:py-14 items-center`}
	background: #66b8d0; /* Old browsers */
	background: -moz-linear-gradient(
		left,
		#1b7db3 0%,
		#2e8fb1 50%,
		#65c0d8 100%
	); /* FF3.6-15 */
	background: -webkit-linear-gradient(
		left,
		#1b7db3 0%,
		#2e8fb1 50%,
		#65c0d8 100%
	); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to left, #1b7db3 0%, #2e8fb1 50%, #65c0d8 100%);
`

export const BannerSubText = tw.div`flex flex-col mb-12 xxl:mb-auto`
export const BannerHook = tw.h3`font-display text-4xl md:text-5xl xl:text-6xl text-white font-bold mb-8 xxl:w-8/12 xxxl:w-6/12`
export const BannerFormText = tw.p`font-display text-3xl lg:text-4xl xl:text-5xl text-white`
export const BannerLink = styled(Link)`
	@media (min-width: 525px) {
		${tw`m-0`}
	}
	${tw`mt-auto`}
`
export const BannerBtn = styled.button`
	@media (min-width: 525px) {
		${tw`text-sm w-auto`}
	}
	${tw`font-display text-white uppercase text-white text-xs md:text-base bg-yellow-500 border-yellow-500 text-black hover:text-white hover:border-yellow-400 hover:bg-yellow-400 leading-none py-3 lg:py-4 px-8 rounded w-full`}
`
export const MineralSwimImg = tw.img`w-7/12 xl:w-6/12 ml-0 mt-2`

export const MoreTitle = tw.h3`font-display text-4xl md:text-5xl xl:text-6xl text-white font-bold mb-6 text-center`
export const MoreDescription = tw.p`font-display text-lg mb-12 text-white text-center w-11/12 leading-snug`

export const Italic = tw.i`italic`
export const QuoteBannerText = tw.p`text-white font-display`
export const QuoteText = styled.blockquote`
	@media (min-width: 525px) {
		${tw`text-2xl`}
	}
	${tw`font-display text-white text-xl  lg:text-3xl xl:text-4xl text-center my-8 md:my-16`}

	> p:first-of-type {
		> b {
			${tw`text-3xl md:text-5xl`}
		}
	}
`
export const QuoteSection = styled.section`
	${tw`w-full py-8 md:py-20`}
	background: linear-gradient(to right, #58c8df 0%,#58aabc 25%, #508699 70%, #426f7c 100%);
`
export const QuoteContainer = styled.div`
	${tw`w-9/12 m-auto flex flex-col items-center justify-center relative`}

	> p:last-of-type {
		@media (min-width: 525px) {
			${tw`text-lg`}
		}
		${tw`text-base md:text-xl lg:text-2xl text-center`}
	}

	&::before {
		content: '❝';
		${tw`text-white absolute`}
		font-family: 'icons';
		font-size: 80px;
		left: -50px;
		top: 0;
		@media (max-width: 524px) {
			left: -20px;
			font-size: 60px;
		}
	}

	&::after {
		content: '❞';
		${tw`text-white absolute`}
		font-family: 'icons';
		font-size: 80px;
		right: -50px;
		bottom: 0;
		@media (max-width: 524px) {
			right: -20px;
			font-size: 60px;
		}
	}
`
export const StyledCheckbox = tw.div`inline-block w-4 h-4 cursor-pointer bg-white rounded`
export const CheckboxContainer = styled.div`
	${tw`flex items-center mr-1`}
	input {
		&[type='checkbox'] {
			${tw`border-0 p-0 h-px w-px -m-px absolute overflow-hidden whitespace-nowrap`}
			clip: rect(0 0 0 0);
			clippath: inset(50%);
		}
	}
`
export const SubItemImageContainer = styled.div`
	max-height: 300px;
	${tw`flex items-center justify-center h-screen cursor-pointer my-4`}
	&:hover {
		img {
			opacity: 0.875;
		}
	}
	img {
		${tw`h-full`}
		@media (min-width: 325px) {
			max-height: 180px;
		}

		@media (min-width: 525px) {
			max-height: 300px;
		}

		@media (min-width: 768px) {
			max-height: 150px;
		}

		@media (min-width: 1024px) {
			max-height: 220px;
		}
		@media (min-width: 1280px) {
			max-height: 280px;
		}
		@media (min-width: 1400px) {
			max-height: 300px;
		}
	}
	@media (min-width: 325px) {
		max-height: 180px;
	}

	@media (min-width: 525px) {
		max-height: 300px;
	}

	@media (min-width: 768px) {
		max-height: 150px;
	}

	@media (min-width: 1024px) {
		max-height: 220px;
	}
	@media (min-width: 1280px) {
		max-height: 280px;
	}
	@media (min-width: 1400px) {
		max-height: 300px;
	}
`
export const Enquire = styled(Link)`
	${tw`font-display w-full md:w-7/12 uppercase border border-yellow-600 hover:border-yellow-500 bg-yellow-600 hover:bg-yellow-500 text-white py-2 md:py-3 px-6 rounded cursor-pointer mb-2`}
`
export const ButtonContainer = tw.div`flex flex-col md:flex-row items-center justify-center`
export const Download = styled(Link)`
	${tw`border-2 border-blue-500 hover:border-blue-400 bg-blue-500 hover:bg-blue-400 rounded-lg px-10 py-4 uppercase mt-2 md:mt-8 w-full md:w-auto text-center`}
`
export const ProductDetailHolder = tw.div`flex flex-col md:w-6/12 items-center justify-center`

export const VideoContainer = styled.div`
	${tw`w-full flex flex-col md:flex-row items-center justify-center bg-gray-100`}

	> iframe {
		min-height: 450px;
		max-height: 500px;
		${tw`order-1 md:order-2`}
	}

	@media (min-width: 1024px) and (max-width: 1279px) {
		> iframe {
			min-height: 300px;
			max-height: 400px;
		}
	}

	@media (max-width: 767px) {
		> iframe {
			min-height: 250px;
			max-height: 350px;
		}
	}
`
export const Novideo = tw.p`text-lg font-display font-bold text-white py-8 lg:py-0`
export const ThumbnailsContainer = tw.div`flex flex-row p-2 md:p-6 bg-gray-100 flex-wrap w-full order-2 md:order-1`
export const ThumbContainer = styled.div`
	${tw`relative px-2 md:px-4 w-6/12 xxxl:w-4/12 my-2 flex flex-col justify-center`}

	> img {
		${tw`mx-auto`}
	}
`
export const Thumbs = styled.img`
	${tw`w-full`}
	src: url(${props => props.src});
`
export const SvgContainer = styled.div`
	${tw`absolute h-full flex top-0 items-center justify-center cursor-pointer w-full`}
`
export const StyledSvg = styled(PlayCircle)`
	@media (max-width: 524px) {
		${tw`w-12`}
	}
	${tw`m-auto cursor-pointer rounded-full h-auto`}
	background-color: rgba(0,0,0,.5);
`
export const ThumbsTitle = styled.p`
	${tw`font-display p-2 text-white cursor-pointer h-full flex items-start text-sm md:text-base bg-gray-600`}
`
export const NullProducts = tw.p`font-display text-dolphin-gray text-2xl bg-white p-8 mt-16 md:mt-20 mb-8 md:mb-12 w-full text-center`
export const Reminder = styled.p`
	${tw`font-display py-2 text-black mb-3 text-sm`}

	> a {
		${tw`underline hover:no-underline font-bold`}
	}
`
export const CheckboxFormContainer = styled(CustomCheckboxContainer)`
	${tw`flex items-center justify-center`}
	input {
		${tw`border-0 p-0 h-px w-px -m-px absolute overflow-hidden whitespace-nowrap `}
		clip: rect(0 0 0 0);
		clippath: inset(50%);
	}
`
